@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    &__circle{
        border-radius: 50%;
        width: 10rem;
        height: 10rem;
        border: 4px solid $secondaryDark;
        display: flex;
        justify-content: center;

    }
}