@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/animation' as *;


.nav {
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    top: 0;
    height: 4rem;
    background: linear-gradient(180deg, rgba(238,228,225,1) 60%, rgba(238,228,225,0) 80%);
    @include mobile-padding;


    @include tablet {
        @include tablet-padding;
    }

    @include desktop{
        @include desktop-padding;
    }


    &__name{
        color: $primary;
        text-decoration: none;
        list-style: none;
    }

        &:hover {
            color: $secondaryDark;
        }

    &__list {
        display:flex;
        justify-content: space-between;
        align-items: inherit;
        padding-left: 0.5rem;
    }

    &__icon{
        @include react-icon;
    }

    &__link {
        text-decoration: none;
        color:$primary;
        font-weight: 600;

        &-item{
            list-style: none;
            text-decoration: none;
            margin-right: 0.5rem;
            cursor: pointer;

            @include tablet {
                margin-right: 1rem;
            }

            &:hover, 
            .nav__link:hover,
            .nav__icon:hover{
                animation: bounce 1s linear infinite;
            }
        }
    }

    &__button{
        background: none;
        text-decoration: none;
        border: none;
        font-size: 0.9rem;
        padding:0;
        cursor: pointer;

        @include desktop{
            font-size: 1.2rem;
        }
    }

}