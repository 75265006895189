@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.aboutme {
    @include top-margin;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile-padding;

    @include tablet {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        @include tablet-padding;
    }

    @include desktop{
        @include desktop-padding;
    }

    &__img{
        width: 12.5rem;
        object-fit: cover;

        @include tablet{
            width: 15rem;
        }
        @include desktop {
            width: 20rem;
        }
        
        
        &-container {
            @include border-radius;
            width: 12.5rem;
            height: 100%;
            overflow: hidden;
            position: relative;
            background-color: $primary;
            //filter: opacity(0.85);
            z-index: 1;
            
            @include tablet{
                width: 15rem;
                //height: 15rem;
            }

            @include desktop {
                width: 20rem;
                //height: 20rem;
            }
        }

    }

    &__textbox {
        background-color: $backgroundColor;
        @include border-radius;
        padding: 0 2rem;
        @include tablet {
            width: 50%;
        }
    }

    
}