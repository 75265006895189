@use '../partials/colors' as *;

@mixin tablet {
    @media (min-width:48rem) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 80rem){
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: 90rem){
        @content;
    }
}

@mixin mobile-padding {
    padding: 2rem;
}

@mixin tablet-padding {
    padding: 3rem;
}

@mixin desktop-padding {
    padding: 3rem 5rem;
    
}


@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    justify-content: center;
    align-items: center;
}

@mixin button {
    background-color: transparent;
    border: 2px solid $primary;
    border-radius: 5px;
    padding: 0.5rem;
    color: $primary;
    margin-top: 1rem;
    cursor: pointer;
    transition: 0.25s;


    &:hover,
    &:focus { 
        background-color: rgba(245,234,229,0.6);
        color: $secondaryDark;
        border-color: $secondaryDark;
    }
}

@mixin top-margin {
    margin-top: 2rem;
}

@mixin react-icon {
    width: 1rem;
    color: $primary;
    cursor: pointer;
    @include tablet{
        width: 1.5rem;
    }
    @include desktop {
        width: 2rem;
    }
}

@mixin border-radius {
    border-radius: 10px;
    
}

@mixin sending-animation {
    animation: sending 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}

