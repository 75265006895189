@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/animation' as *;

.hero {
    @include mobile-padding;

    @include tablet {
        @include tablet-padding;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    @include desktop {
        @include desktop-padding;
        max-width: none;
        width: 100%;
    } 

    @include large-desktop {
        max-width: 1600px;
    }

    &__container {
        background: linear-gradient(180deg, rgba(238,228,225,1) 70%, rgba(238,228,225,0) 80%);

        @include top-margin;
        
        @include large-desktop {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__textbox{
        display: flex;
        flex-direction: column;

        @include tablet{
            width: 50%;
            height: 100%;
        }
    }

    &__headline{
        font-size: 3.5rem;
        width: 100%;
        margin: 0;
        line-height: 3.5rem;

        @include tablet {
            font-size: 6rem;
            margin-top: 0;
            line-height: 5rem;
        }

        @include desktop {
            font-size: 8rem;
            width: 50rem;
            line-height: 6.5rem;
        }

        &--smaller{
            font-size: 2rem;
            margin: 0;
            display: block;

            @include desktop {
                font-size: 2.5rem;
            }   
        }

        &--color{
            color:$primary;
            
        }
    }
    &__tagline {
        @include tablet{
            width: 100%;
        }
    }

    &__img {
        display: none;

        @include tablet{
            display:block;
        }

        @include desktop {
            width: 30rem;
            height: 35rem;
        }

        @include large-desktop{
            height: 40rem;
        }

        &-container{
            display: none;

            @include tablet{
                display: block;
            }
        }
        
    }

    &__button {
        @include button;
        font-size: 1rem;
        width: fit-content;
        background-color: $backgroundColor;

        @include tablet {
            font-size: 1.2rem;
        }

        &:hover .button__icon{
            @include sending-animation;
        }
    }

    &__arrow-down{
        display:flex;
        color: $primary;
        font-size: 1rem;
        margin-top: 2rem;
        animation: arrow-down-bounce 1s infinite linear;
        justify-self: center;
    }

}

