@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/animation' as *;

.project {
    margin: 1rem 0;
    position: relative;
    display: flex;
    justify-content: center;

    &__container {
        border-radius: 10px;
        background-color: $backgroundColor;

        &--invert {
            flex-direction: row-reverse;
        }

        @include tablet {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include desktop {
            justify-content: space-between;
            height: 30rem;
            max-width: 80rem;
        }

    }

    &__title-box{
        display: flex;
        justify-content: space-between;

    }

    &__title {
        font-weight: 800;
        font-size: 2rem;
        margin: 0;
        margin-bottom: 0.5rem;
        

        @include desktop {
            font-size: 2rem;
        }
    }

    &__description {
        @include desktop{
            margin: 0;
        }
    }

    &__img-container {
        @include border-radius;

        @include tablet {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1rem;
            //border: 2px solid red;
            height: 26rem;
        }
    }
    

    &__img {
        display: none;

        @include tablet{
            @include border-radius;
            display: block;
            width: 38rem;
            opacity: 0.6;
            padding: 2rem;
            transition: opacity 0.5s ease-in-out;
        }

        &:hover {
            opacity: 1;
        }

    }

    &__label-box {
        border-radius: 10px;
        width: 100%;
        @include flex-column;
        align-items: center;
        padding: 2rem;
        font-size: 0.9rem;
        transition: all 0.3s ease-in-out;
        //border: 2px solid violet;

        @include tablet {
            width: 20rem;
        }

        @include desktop {
            height: 30rem;
            width: 80%;
            justify-content: center;
        }

        &:hover{
            animation: fade_in_bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
            bottom: 0px;
        }

    }

    &__link{
        @include button;
        text-decoration: none;
        text-transform: capitalize;
        border: 1px solid $primary;
        margin: 0.25rem;
        font-size: 1rem;
        font-weight: 800;

        @include tablet {
            margin: 1rem 0.5rem 1rem 0;
        }



        &-box{
            width: 100%;
            display: flex;
            justify-content: flex-start;

            &--reverse {
                @include tablet{
                    flex-direction: row-reverse;

                }
            }
        }
    }

    &__tech {
        padding: 1rem 0;

        &-icon {
            height: 2rem;
            padding: 0.2rem;

            @include tablet {
                height: 2.5rem;
            }

            @include desktop{
                margin-top: 0.5rem;
                height: 2.5rem;
            }
        }
    }
}