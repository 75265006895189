@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.connect {
    @include mobile-padding;
    @include flex-column;
    background-color: $backgroundColor;
    @include border-radius;
    justify-content: space-between;
    align-items: center;
    margin: 2rem;
    

    @include tablet{
        @include tablet-padding;
        width: 40rem;
    }

    @include desktop {
        @include desktop-padding;
        width: 60rem;
    }

    &__formbox{
        width: 100%;
        display: flex;
        max-width: 28rem;

        @include tablet{
            max-width: none;
            justify-content: space-between;
            align-items: center;
        }

    }

    &__img{
        display: none;

        @include tablet {
            display: block;
            align-items: flex-start;
            width: 35rem;
            margin: 2rem;
        }
    
        &-sent{
            position: absolute;
            top: 10rem;
            left: 3rem;

        }

    }
}

.modal {
    &__icon{
        margin: 1rem;
        @include react-icon;
        align-self: flex-end;
    }
}