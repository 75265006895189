$backgroundColor:#eee4e1;
//$primaryLight:#EDEDE9;
$primary:  #344E8C;
$primaryDark:#4168B0;
$secondary:#344E8C; 
$secondaryDark: #203458;


//#7F9BD0 - old
//#F6ECEC
//#EDEDE9

//previous colors
// $primaryLight: #E8F3F1;
// $primary: #83C5BE;
// $primaryDark: #853C4A;
// $secondary: #FFF6D9;
// $secondaryDark: #212529;