@use './colors' as *;
@use './mixins' as *;

@font-face {
    font-family: 'nunito';
    src: url('../../assets/fonts/Nunito-Regular.woff') format('woff'),
    url('../../assets/fonts/alternative-fonts/Nunito-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nunito';
    src: url('../../assets/fonts/Nunito-Medium.woff') format('woff'),
    url('../../assets/fonts/alternative-fonts/Nunito-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'nunito';
    src: url('../../assets/fonts/Nunito-Bold.woff') format('woff'),
    url('../../assets/fonts/alternative-fonts/Nunito-Bold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'cedarville-cursive';
    src: url('../../assets/fonts/cedarville-cursive.woff') format('woff'),
    url('../../assets/fonts/alternative-fonts/cedarville-cursive.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

body {
    color: $primary;
    font-family: 'nunito', sans-serif;
    font-weight: 400;
}

h2 {
    font-weight: 800;
    @include tablet {
        font-size: 2.5rem;
    }
    @include desktop {
        font-size: 3.5rem;
    }
}

p,li{
    font-size: 0.9rem;
    @include tablet {
        font-size: 1rem;
    }

    @include desktop {
        font-size: 1.2rem;
    }
}

label, input {
    font-family: 'nunito', sans-serif;
    font-weight: 800;
}

button {
    font-family: 'nunito', sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
}

