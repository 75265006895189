@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.projects {
    padding: 0 2rem 2rem 2rem;
    width: 100%;

    @include tablet{
        @include flex-column;
        @include tablet-padding;
        align-items: center;
    }
    @include desktop {
        @include desktop-padding;
    }



    &__header{
        @include flex-column;
        justify-content: center;
        align-items: center;

        @include tablet{
            flex-direction: row;
            margin: -2rem 0;
        }
        @include desktop{
            justify-content: flex-start;
        }
    }

    &__img{
        width: 15rem;
        height: auto;

        @include tablet {
            width: 20rem;
        }

        @include desktop {
            width: 25rem;
        }
    }

    &__headline {
        margin-top: 1rem;
        margin-bottom: 2rem;

    }

    &__box {
        position: relative;
        @include flex-column;
        justify-content: center;
        align-items: center;

        @include tablet{
            position: static;
        }


    }
}