@keyframes bounce {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-2px);
    }
    100%{
        transform: translateY(0);
    }
    
}


@keyframes sending {
    0%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(5px,-5px);
    }
    
}

@keyframes arrow-up-bounce {
    0%{
        transform: translateY(1px);
    }
    50%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(1px);
    }
    
}

@keyframes arrow-down-bounce {
    0%{
        transform: translateY(-5px);
    }
    50%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-5px);
    }
    
}