@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.footer{
    display: flex;
    justify-content: center;
    align-self:flex-end;
    background: linear-gradient(180deg, rgba(238,228,225,0) 0%, rgba(238,228,225,1) 20%);
    width: 100%;
    &__text{
        font-weight: 600;
    }
    &__link {
        color: $primary;
        text-decoration: none;
        cursor: pointer;
        font-size: 2rem;
        margin-left: 0.5rem;
        margin-top: 0.5rem;

        @include tablet{
            margin-top: 1rem;
        }
    }
}