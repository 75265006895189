@use './styles/partials/typography' as *;
@use './styles/partials/colors' as *;
@use './styles/partials/mixins' as *;

*, *::before, *::after {
    box-sizing: border-box;
    }

body {
    margin: 0;
    overflow-x: hidden;
    //background-color: $backgroundColor;
    background-color: #f2ebe9;
opacity: 1;
background-size: 13px 13px;
background-image: repeating-linear-gradient(45deg, #b1c1e3 0, #b1c1e3 1.3px, #f2ebe9 0, #f2ebe9 50%);
}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;

    &--active{
        transform: translate(0);
        opacity: 1;
    }
}